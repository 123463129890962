
import { defineComponent, ref, onMounted } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import AddHsCodeModal from "@/views/products/hs-code-management/AddHsCodeModal.vue";
import viewHsCodeLogModal from "@/views/products/hs-code-management/viewHsCodeLogModal.vue";
import HsCodeImportModal from "@/views/products/hs-code-management/ImportModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiHsCode } from "@/core/api";
import _ from "lodash";
import fileDownload from "js-file-download";
import { useI18n } from "vue-i18n";
import {
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { HsCodeItem } from "@/core/directive/interface/hsCodeManagement";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";

interface SortOption {
  field: string;
  direction: string;
}

interface FilterOption {
  field: string;
  value: string;
  from?: string;
  to?: number;
  condition?: string;
}

export default defineComponent({
  name: "hs-code-management",
  components: {
    MBDatatable,
    AddHsCodeModal,
    viewHsCodeLogModal,
    HsCodeImportModal,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const loading = ref(true);
    const tableData = ref<Array<HsCodeItem>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const sortOptions = ref<Array<SortOption>>([
      { field: "updated_at", direction: "desc" },
    ]);
    const filterOptions = ref<Array<FilterOption>>([]);
    const { t } = useI18n();

    const editId = ref("");
    const viewId = ref("");
    const disabledExport = ref<boolean>(false);
    const disabledExportLog = ref<boolean>(false);

    const tableHeader = ref([
      // {
      //   name: "ID",
      //   key: "id",
      //   sortable: true,
      // },
      {
        name: t("hsCodeManagement.brandName"),
        key: "brand_name",
        sortable: false,
      },
      {
        name: t("hsCodeManagement.brandArticleNo"),
        key: "brand_article_no",
        sortable: false,
      },
      {
        name: t("hsCodeManagement.articleName"),
        key: "name",
        sortable: false,
      },
      {
        name: t("hsCodeManagement.material"),
        key: "material",
        sortable: false,
      },
      {
        name: t("hsCodeManagement.origin"),
        key: "make_in",
        sortable: false,
      },
      {
        name: t("hsCodeManagement.hsCode"),
        key: "hs_code",
        sortable: false,
      },
      // {
      //   name: t("hsCodeManagement.status"),
      //   key: "status",
      //   sortable: false,
      // },
      {
        name: t("hsCodeManagement.updatedAt"),
        key: "updated_at",
        sortable: false,
      },
      {
        name: t("common.actions"),
        key: "actions",
        sortable: false,
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
      getHsCodeManagementList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    });

    const getHsCodeManagementList = (
      page: number,
      pageSize: number,
      search?: string,
      sortOptions?: Array<SortOption>,
      filterOptions?: Array<FilterOption>
    ) => {
      loading.value = true;
      ApiHsCode.getHsCodeList({
        page: page,
        page_size: pageSize,
        search_value: search,
        sort_orders: sortOptions,
        filter_group: filterOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
            viewId.value = "";
            editId.value = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] !== "") {
          filterArr.push({
            field: item,
            value: val[item],
            condition: "eq",
          });
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getHsCodeManagementList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getHsCodeManagementList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getHsCodeManagementList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getHsCodeManagementList(
        page,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getHsCodeManagementList(
        currentPage.value,
        pagesize,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const debounceSearch = _.debounce(getHsCodeManagementList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const updateList = () => {
      getHsCodeManagementList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onAddButtonClick = () => {
      editId.value = "";
    };

    const onEditButtonClick = (id) => {
      editId.value = id;
    };

    const onViewButtonClick = (id) => {
      viewId.value = id;
    };

    const deleteHsCode = async (id) => {
      loading.value = true;
      const { data } = await ApiHsCode.deleteHsCode({
        id: id,
      });
      loading.value = false;
      if (data.code == 0) {
        updateList();
        Swal.fire({
          text: t("common.deletedSuccessfully"),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    };

    const onDeleteButtonClick = (id) => {
      Swal.fire({
        text: t("common.secondConfirmation"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.confirm"),
        cancelButtonText: t("common.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deleteHsCode(id);
        }
      });
    };

    const fileExport = () => {
      disabledExport.value = true;
      ApiHsCode.exportHsCode({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        download_data_type: "stream",
      })
        .then((data) => {
          disabledExport.value = false;
          const disposition = data.headers["content-disposition"];
          fileDownload(data.data, disposition.split("filename=")[1]);
        })
        .catch((error) => {
          disabledExport.value = false;
          console.log(error);
        });
    };

    const fileExportLog = () => {
      disabledExportLog.value = true;
      ApiHsCode.exportHsCodeLog({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        download_data_type: "stream",
      })
        .then((data) => {
          disabledExportLog.value = false;
          const disposition = data.headers["content-disposition"];
          fileDownload(data.data, disposition.split("filename=")[1]);
        })
        .catch((error) => {
          disabledExportLog.value = false;
          console.log(error);
        });
    };

    return {
      t,
      formatDate,
      formatDateTime,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      editId,
      disabledExport,
      disabledExportLog,
      viewId,
      getHsCodeManagementList,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      removeSearch,
      onColumnSort,
      handleFilter,
      handleFilterReset,
      updateList,
      onAddButtonClick,
      onEditButtonClick,
      onDeleteButtonClick,
      fileExport,
      fileExportLog,
      onViewButtonClick,
    };
  },
});
