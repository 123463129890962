
import { defineComponent, ref } from "vue";
import { Upload } from "@element-plus/icons-vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import { ApiHsCode } from "@/core/api";
import JwtService from "@/core/services/JwtService";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "hs_code_import-modal",
  emits: ["update-list"],
  components: {},
  setup(props, { emit }) {
    const loading = ref<boolean>(false);
    const hsCodeImportModalRef = ref<null | HTMLElement>(null);
    const { showServerErrorMsg } = mixin();

    const { t } = useI18n();
    const uploadRef = ref();
    const fileList = ref([]);
    const uploadUrl = ApiHsCode.uploadHsCodeExcel();
    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });

    const handleUploadSuccess = (res, file) => {
      if (res.code == 0) {
        loading.value = false;
        Swal.fire({
          text: getSuccessTips(res.data.successQty, res.data.ignore),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          clearUploadFiles();
          hideModal(hsCodeImportModalRef.value);
          emit("update-list");
        });
      } else {
        clearUploadFiles();
        hideModal(hsCodeImportModalRef.value);
        showServerErrorMsg(res);
      }
    };

    const handleChange = (uploadFile, uploadFiles) => {
      fileList.value = uploadFiles;
    };

    const handleRemove = (uploadFile, uploadFiles) => {
      fileList.value = uploadFiles;
    };

    const getSuccessTips = (successQty, ignore) => {
      let content = "";
      if (ignore) {
        content =
          t("hsCodeManagement.importSuccess", [
            successQty,
            ignore.split(",").length,
          ]) + t("hsCodeManagement.ignoredRecords", [ignore]);
      } else {
        content = t("hsCodeManagement.importSuccess", [successQty, 0]);
      }
      return content;
    };

    const submitImport = () => {
      loading.value = true;
      uploadRef.value!.submit();
    };

    const clearUploadFiles = () => {
      uploadRef.value?.clearFiles();
      loading.value = false;
    };

    return {
      t,
      loading,
      handleUploadSuccess,
      submitImport,
      uploadUrl,
      uploadHeaders,
      hsCodeImportModalRef,
      uploadRef,
      Upload,
      fileList,
      handleChange,
      handleRemove,
    };
  },
});
