
import { defineComponent, ref, onBeforeMount, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiHsCode } from "@/core/api";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import MBDatatablePlus from "@/components/mb-datatable/DataTablePlus.vue";
import { HsCodeLogItem } from "@/core/directive/interface/hsCodeManagement";
import { formatDateTime } from "@/core/directive/function/common";

export default defineComponent({
  name: "view-hs-code-log-modal",
  props: {
    id: { type: String, required: false },
  },
  components: {
    MBDatatablePlus,
  },
  setup(props) {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const viewHsCodeLogModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const { t } = useI18n();
    const viewId = ref(props.id);

    const updateLogData = ref({
      tableHeader: [
        {
          name: t("hsCodeManagement.updatedBy"),
          key: "username",
          sortable: false,
        },
        {
          name: t("hsCodeManagement.status"),
          key: "status",
          sortable: false,
        },
        {
          name: t("hsCodeManagement.updatedAt"),
          key: "created_at",
          sortable: false,
        },
        {
          name: t("hsCodeManagement.updateDetails"),
          key: "old_value",
          sortable: false,
        },
      ],
      list: [] as HsCodeLogItem[],
      total: 100,
      currentPage: 1,
      pageSize: 100,
    });

    watch(
      () => props.id,
      (newVal) => {
        if (newVal) {
          viewId.value = newVal;
          getHsCodeUpdateLog();
        }
      }
    );

    const getHsCodeUpdateLog = async () => {
      loading.value = true;
      const { data } = await ApiHsCode.getHsCodeLog({
        id: viewId.value,
      });
      loading.value = false;
      if (data.code == 0) {
        updateLogData.value.list.splice(
          0,
          updateLogData.value.list.length,
          ...data.data.log
        );
        updateLogData.value.list.push({
          id: "",
          created_at: data.data.created_at,
          created_uid: data.data.created_uid,
          type: "",
          action: "create",
          info: "",
          object: "",
          object_id: "",
          ip_address: "",
          old_value: data.data.old_hs_code,
          new_value: "",
          username: data.data.username,
        });
      }
    };

    const getLogDetails = (oldVal, newVal) => {
      let content = "";
      if (newVal == "") {
        content = t("hsCodeManagement.createHsCodeLog", [oldVal]);
      } else {
        const oldLog = JSON.parse(oldVal);
        const newLog = JSON.parse(newVal);
        content = t("hsCodeManagement.hsCodeChangeDetails", [
          oldLog.hs_code,
          newLog.hs_code,
        ]);
      }
      return content;
    };

    const handleDiscard = () => {
      hideModal(viewHsCodeLogModalRef.value);
    };

    onBeforeMount(() => {
      if (viewId.value) {
        getHsCodeUpdateLog();
      }
    });

    return {
      props,
      loading,
      updateLogData,
      formRef,
      viewHsCodeLogModalRef,
      submitButton,
      t,
      handleDiscard,
      formatDateTime,
      getLogDetails,
    };
  },
});
