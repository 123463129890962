
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  watch,
  getCurrentInstance,
} from "vue";
import arraySort from "array-sort";
import { useI18n } from "vue-i18n";

interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

interface IHeaderConfiguration {
  name?: string;
  key: string;
  sortingField?: string;
  sortable?: boolean;
  align?: string;
  offset?: string;
  className?: string;
}

interface TotalArrayItem {
  index: number;
  string: string[];
  span: number[];
}

export default defineComponent({
  name: "mb-datatable-plus",
  emit: ["current-change", "sort", "items-per-page-change", "select-change"],
  props: {
    tableHeader: {
      type: Array as () => Array<IHeaderConfiguration>,
      required: true,
    },
    tableData: { type: Array, required: true },
    emptyTableText: { type: String, default: "No data found" },
    loading: { type: Boolean, default: false },
    currentPage: { type: Number, default: 1 },
    enableItemsPerPageDropdown: { type: Boolean, default: true },
    enableItemsNumber: { type: Boolean, default: true },
    total: { type: Number, default: 0 },
    rowsPerPage: { type: Number, default: 10 },
    order: { type: String, default: "asc" },
    diyOrder: { type: Boolean, default: false },
    sortLabel: { type: String, default: "" },
    selection: { type: Boolean, default: false },
    selectType: { type: String, default: "checkbox" },
    selectRadioName: { type: String, default: "radio" },
    expandAll: { type: Boolean, default: false },
    showExpandBtn: { type: Boolean, default: true },
    sameTable: { type: Boolean, default: true },
    emptySmall: { type: Boolean, default: false },
    totalArray: {
      type: Array as () => TotalArrayItem[],
      default: () => {
        return [];
      },
    },
  },
  components: {},
  setup(props, { emit }) {
    const data = ref(props.tableData);
    const currentSort = ref<string>("");
    const order = ref(props.order);
    const label = ref(props.sortLabel);
    const pagination = ref<IPagination>({
      page: 1,
      total: props.total,
      rowsPerPage: props.rowsPerPage,
    });
    const rowsData = ref<any>([]);
    const trIndex = ref(-1);
    const isAllExpand = ref<boolean>(props.expandAll);
    const isAllChecked = ref<boolean>(false);
    const selectedRows = ref<any>([]);
    const isMobile = ref<boolean>(false);

    const { t, te } = useI18n();

    const vnodeProps = getCurrentInstance()?.vnode.props || {};
    const mediaQuery = window.matchMedia("(max-width: 576px)");

    watch(data.value, (newVal) => {
      if ("onCurrentChange" in vnodeProps) {
        currentSort.value = label.value + order.value;
        tableDataDeepCopy(newVal);
      } else {
        pagination.value.total = data.value.length;
      }
    });

    watch(
      () => props.currentPage,
      (newVal) => {
        pagination.value.page = newVal;
      }
    );

    watch(
      () => props.rowsPerPage,
      (newVal) => {
        pagination.value.rowsPerPage = newVal;
      }
    );

    watch(
      () => props.total,
      (newVal) => {
        pagination.value.total = newVal;
      }
    );

    watch(
      () => props.expandAll,
      (newVal) => {
        isAllExpand.value = newVal;
        toggleTableExpand(newVal);
      }
    );

    watch(
      () => props.sortLabel,
      (newVal) => {
        label.value = newVal;
        currentSort.value = newVal + order.value;
      }
    );

    watch(
      () => props.order,
      (newVal) => {
        order.value = newVal;
        currentSort.value = label.value + newVal;
      }
    );

    const tableDataDeepCopy = (data) => {
      let rowsArr: any = JSON.parse(JSON.stringify(data));
      rowsArr.forEach((item) => {
        item = Object.assign(item, { isChecked: false, isExpand: false });
      });
      rowsData.value.splice(0, rowsData.value.length, ...rowsArr);
      clearRadioSelect();
      clearSelect();
      toggleTableExpand(isAllExpand.value);
    };

    const rowsStartNumber = computed(() => {
      return pagination.value.total == 0
        ? 0
        : (pagination.value.page - 1) * pagination.value.rowsPerPage + 1;
    });

    const rowsEndNumber = computed(() => {
      return pagination.value.page * pagination.value.rowsPerPage >=
        pagination.value.total
        ? pagination.value.total
        : pagination.value.page * pagination.value.rowsPerPage;
    });

    const colspanNumber = computed(() => {
      let total = props.tableHeader.length;
      if (props.selection) {
        total += 1;
      }
      if (props.showExpandBtn) {
        total += 1;
      }
      return total;
    });

    const getItems = computed(() => {
      if ("onCurrentChange" in vnodeProps) {
        return rowsData.value;
      } else {
        const clone = JSON.parse(JSON.stringify(data.value));
        const startFrom =
          pagination.value.page * pagination.value.rowsPerPage -
          pagination.value.rowsPerPage;
        return clone.splice(startFrom, pagination.value.rowsPerPage);
      }
    });

    const currentPageChange = (val) => {
      if ("onCurrentChange" in vnodeProps) {
        emit("current-change", val);
      } else {
        pagination.value.page = val;
      }
    };

    const sort = (columnName, sortable) => {
      if (sortable === false) {
        return;
      }
      if (props.diyOrder) {
        emit("diySortClick", { columnName: columnName });
      } else {
        if ("onSort" in vnodeProps) {
          if (order.value === "asc") {
            label.value = columnName;
            order.value = "desc";
            emit("sort", { columnName: columnName, order: "desc" });
          } else {
            label.value = columnName;
            order.value = "asc";
            emit("sort", { columnName: columnName, order: "asc" });
          }
        } else {
          if (order.value === "asc") {
            label.value = columnName;
            order.value = "desc";
            arraySort(data.value, columnName, { reverse: false });
          } else {
            label.value = columnName;
            order.value = "asc";
            arraySort(data.value, columnName, { reverse: true });
          }
        }
      }
      currentSort.value = columnName + order.value;
    };

    const setItemsPerPage = (event) => {
      if ("onItemsPerPageChange" in vnodeProps) {
        emit("items-per-page-change", parseInt(event.target.value));
      } else {
        pagination.value.rowsPerPage = parseInt(event.target.value);
      }
    };

    const toggleTableExpand = (flag) => {
      rowsData.value.forEach((item: any) => {
        item.isExpand = flag;
      });
    };

    const onToggleAllClick = () => {
      isAllExpand.value = !isAllExpand.value;
      toggleTableExpand(isAllExpand.value);
    };

    const onToggleBtnClick = (i) => {
      (rowsData.value[i] as any).isExpand = !(rowsData.value[i] as any)
        .isExpand;
    };

    const toggleCheckboxAllSelect = () => {
      isAllChecked.value = !isAllChecked.value;
      rowsData.value.forEach((item: any) => {
        item.isChecked = isAllChecked.value;
      });
      getSelectedRows();
      emit("select-change", selectedRows.value);
    };

    const toggleCheckboxRowSelect = (i) => {
      (rowsData.value[i] as any).isChecked = !(rowsData.value[i] as any)
        .isChecked;
      getSelectedRows();
      emit("select-change", selectedRows.value);
    };

    const toggleRadioSelect = (i,item) => {
      rowsData.value.forEach((item: any) => {
        item.isChecked = false;
      });
      (rowsData.value[i] as any).isChecked = !(rowsData.value[i] as any)
          .isChecked;
      emit("select-change", item);
    };

    const clearRadioSelect = () => {
      rowsData.value.forEach((item: any) => {
        item.isChecked = false;
      });
      //emit("select-change", null);
    };

    const clearSelect = () => {
      isAllChecked.value = false;
      rowsData.value.forEach((item: any) => {
        item.isChecked = false;
      });
      selectedRows.value = [];
      emit("select-change", selectedRows.value);
    };

    const getSelectedRows = () => {
      let rows: any = [];
      rowsData.value.forEach((item: any) => {
        if (item.isChecked) {
          rows.push(item);
        }
      });
      selectedRows.value.splice(0, selectedRows.value.length, ...rows);
      isAllChecked.value = data.value.length == selectedRows.value.length;
      return selectedRows.value;
    };

    const onTableTrClick = (index) => {
      trIndex.value = index;
      onToggleBtnClick(index);
    };

    const translate = (text, params?) => {
      if (te(text)) {
        return t(text, params);
      } else {
        return text;
      }
    };

    const checkViewport = (e) => {
      if (e.matches) {
        isMobile.value = true;
      } else {
        isMobile.value = false;
      }
    };

    onMounted(() => {
      currentSort.value = label.value + order.value;
      pagination.value.total = props.total;
      pagination.value.rowsPerPage = props.rowsPerPage;
      checkViewport(mediaQuery);
      mediaQuery.addListener(checkViewport);
    });

    return {
      pagination,
      currentPageChange,
      getItems,
      sort,
      currentSort,
      setItemsPerPage,
      rowsStartNumber,
      rowsEndNumber,
      colspanNumber,
      trIndex,
      onTableTrClick,
      translate,
      onToggleAllClick,
      onToggleBtnClick,
      isAllExpand,
      isAllChecked,
      toggleCheckboxAllSelect,
      toggleCheckboxRowSelect,
      toggleRadioSelect,
      clearRadioSelect,
      clearSelect,
      getSelectedRows,
      isMobile,
    };
  },
});
