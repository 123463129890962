
import { defineComponent, ref, onBeforeMount, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiBase, ApiHsCode } from "@/core/api";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { TaggingItem } from "@/core/directive/interface/common";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "add-hs-code-modal",
  props: {
    id: { type: String, required: false, default: "" },
  },
  components: {},
  emits: ["update-list"],
  setup(props, { emit }) {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const addHsCodeModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const { t } = useI18n();
    const editId = ref(props.id);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: "",
      brand_id: "",
      brand_article_no: "",
      category: "",
      sex: "",
      color: "",
      material: "",
      make_in: "",
      hs_code: "",
      country_iso_2: "DE",
      status: 10,
    });

    const rules = ref({
      brand_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      brand_article_no: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      category: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      sex: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      color: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      material: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      make_in: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      hs_code: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
        {
          pattern: /^\d{8}$/,
          message: t("hsCodeManagement.hsCodeValid"),
          trigger: "change",
        },
      ],
    });

    watch(
      () => props.id,
      (newVal) => {
        editId.value = newVal;
        if (newVal) {
          formData.value.id = newVal;
          getHsCodeData();
        } else {
          resetForm();
        }
      }
    );

    const options = ref({
      brandOptions: [] as TaggingItem[],
      brandLoading: false,
      sex: [
        { label: "Frau", value: "Frau" },
        { label: "Mann", value: "Mann" },
        { label: "Kids", value: "Kids" },
        { label: "Mädchen", value: "Mädchen" },
        { label: "Junge", value: "Junge" },
        { label: "Unisex", value: "Unisex" },
        { label: "Baby", value: "Baby" },
      ],
      originCountry: [] as TaggingItem[],
    });

    const getBrandSourceData = async (value) => {
      options.value.brandLoading = true;
      let params = {
        search_value: value,
      };
      const { data } = await ApiBase.getBrandSourceData(params);
      options.value.brandLoading = false;
      if (data.code == 0) {
        options.value.brandOptions = data.data;
      }
    };

    const getCountryData = async () => {
      const { data } = await ApiBase.getCountryData();
      if (data.code == 0) {
        options.value.originCountry = data.data;
      }
    };

    const debounceBrandSourceSearch = _.debounce(getBrandSourceData, 1000);
    const searchBrandSourceItems = (query: string) => {
      debounceBrandSourceSearch(query);
    };

    const getBrandOptionsById = async (value) => {
      options.value.brandLoading = true;
      let params = {
        id: value,
      };
      const { data } = await ApiBase.getBrandSourceData(params);
      options.value.brandLoading = false;
      if (data.code == 0) {
        options.value.brandOptions = data.data;
      }
    };

    const getHsCodeData = async () => {
      loading.value = true;
      const { data } = await ApiHsCode.getHsCodeDetails({
        id: formData.value.id,
      });
      loading.value = false;
      if (data.code == 0) {
        formData.value = Object.assign(formData.value, data.data);
        if (data.data.brand_id) {
          getBrandOptionsById(data.data.brand_id);
        }
      }
    };

    const addHsCode = () => {
      ApiHsCode.saveHsCode(formData.value)
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              resetForm();
              hideModal(addHsCodeModalRef.value);
              emit("update-list");
            });
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const checkHsCode = () => {
      loading.value = true;
      ApiHsCode.checkHsCode(formData.value)
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            if (data.data.hs_code) {
              Swal.fire({
                text: t("hsCodeManagement.hsCodeExists", [data.data.hs_code]),
                icon: "warning",
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: t("common.confirm"),
                cancelButtonText: t("common.cancel"),
                customClass: {
                  confirmButton: "btn btn-primary",
                  cancelButton: "btn btn-danger",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  addHsCode();
                }
              });
            } else {
              addHsCode();
            }
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const editHsCode = () => {
      ApiHsCode.updateHsCode(formData.value)
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              resetForm();
              hideModal(addHsCodeModalRef.value);
              emit("update-list");
            });
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          if (editId.value) {
            Swal.fire({
              text: t("hsCodeManagement.hsCodeUpdateConfirm"),
              icon: "warning",
              showCancelButton: true,
              buttonsStyling: false,
              confirmButtonText: t("common.confirm"),
              cancelButtonText: t("common.cancel"),
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-danger",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                editHsCode();
              } else {
                loading.value = false;
                submitButton.value?.removeAttribute("data-kt-indicator");
              }
            });
          } else {
            checkHsCode();
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      resetForm();
      hideModal(addHsCodeModalRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onBeforeMount(() => {
      getCountryData();
      if (editId.value) {
        getHsCodeData();
      }
    });

    return {
      props,
      loading,
      options,
      formData,
      rules,
      formRef,
      editId,
      addHsCodeModalRef,
      submitButton,
      t,
      submit,
      handleDiscard,
      resetForm,
      searchBrandSourceItems,
    };
  },
});
