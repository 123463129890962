import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "modal_view_hs_code",
  ref: "viewHsCodeLogModalRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-1000px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  class: "modal-header",
  id: "kt_modal_view_hs_code_header"
}
const _hoisted_5 = { class: "fw-bolder" }
const _hoisted_6 = {
  "data-bs-dismiss": "modal",
  class: "btn btn-icon btn-sm btn-active-icon-primary"
}
const _hoisted_7 = { class: "svg-icon svg-icon-1" }
const _hoisted_8 = { class: "modal-body py-10" }
const _hoisted_9 = {
  class: "scroll-y me-n7 pe-7",
  id: "kt_modal_view_hs_code_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_modal_view_hs_code_header",
  "data-kt-scroll-wrappers": "#kt_modal_view_hs_code_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_10 = { class: "table-responsive" }
const _hoisted_11 = { class: "modal-footer flex-center" }
const _hoisted_12 = { class: "svg-icon svg-icon-3 ms-0 me-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_MBDatatablePlus = _resolveComponent("MBDatatablePlus")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.t("hsCodeManagement.updateLog")), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_MBDatatablePlus, {
                "current-page": _ctx.updateLogData.currentPage,
                "rows-per-page": _ctx.updateLogData.pageSize,
                showExpandBtn: false,
                "table-data": _ctx.updateLogData.list,
                "table-header": _ctx.updateLogData.tableHeader,
                total: _ctx.updateLogData.total,
                enableItemsPerPageDropdown: false,
                enableItemsNumber: false,
                emptySmall: true
              }, {
                "cell-username": _withCtx(({ row: item }) => [
                  _createTextVNode(_toDisplayString(item.username || "--"), 1)
                ]),
                "cell-status": _withCtx(({ row: item }) => [
                  _createTextVNode(_toDisplayString(item.action == "create"
                      ? _ctx.t("hsCodeManagement.create")
                      : _ctx.t("hsCodeManagement.update")), 1)
                ]),
                "cell-created_at": _withCtx(({ row: item }) => [
                  _createTextVNode(_toDisplayString(item.created_at ? _ctx.formatDateTime(item.created_at) : "--"), 1)
                ]),
                "cell-old_value": _withCtx(({ row: item }) => [
                  _createTextVNode(_toDisplayString(_ctx.getLogDetails(item.old_value, item.new_value)), 1)
                ]),
                _: 1
              }, 8, ["current-page", "rows-per-page", "table-data", "table-header", "total"])
            ])
          ])
        ])), [
          [_directive_loading, _ctx.loading]
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-primary",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleDiscard && _ctx.handleDiscard(...args)))
          }, [
            _createElementVNode("span", _hoisted_12, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
            ]),
            _createTextVNode(" " + _toDisplayString(_ctx.t("common.close")), 1)
          ])
        ])
      ])
    ])
  ], 512))
}